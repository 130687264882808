
.Calendar {
 
  margin-top: 60px;
  margin-left:1rem;
  margin-right:1rem;
 
  align-items: center;
}

.AppBar {
  /* background-color: white;*/
  font-size: 2.6rem;
  color: #f1f20a;
  font-weight: 400;
  padding: 0;
  margin: 0;

  font-family: "Grandstander";
  /* background: #fff;*/
  line-height: 105px;
  height: 100px;
  text-align: center;

  background-image: linear-gradient(135deg, #466df3, #1244eb);
  box-shadow: 0 0 7px rgb(11 24 72 / 51%), 0 6px 6px rgb(0 0 0 / 11%);
  border-bottom: 1px solid #1244eb;
}
.AppBarContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Carousel {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 4rem;
}
.RelatedTopicContainer {
  text-align: center;

  margin: 3rem;
}
.RelatedTopic {
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.RelatedTopic:nth-child(3n + 2) {
  padding-right: 2rem;
}
.RelatedTopic:nth-child(3n + 3) {
  padding-left: 1rem;
  padding-right: 1rem;
}
.RelatedTopic:nth-child(3n + 4) {
  padding-left: 2rem;
}

.Logo {
  /*position: absolute;
  top: 520px;
  left: calc(50% - 50px);*/

  filter: drop-shadow(3px 3px 3px #33333366);
  width: 120px;
  z-index: 1000;
  position: absolute;
  left: 20px;
  top: 0px;
}

.CatchPhrase {
  font-size: 1rem;
  font-style: italic;
  color: rgb(44, 44, 44);

  text-align: center;
  font-weight: 200;
  margin-top: 15rem;
  margin-bottom: 2rem;
}

.TopMenu {
  position: absolute;
  top: 70px;
  right: 2.5rem;
}

.TopMenu a {
  color: #cbcbcb;
}

.TopMenuSeparator {
  color: #c7c7c7;
}

.BottomLinks {
  margin-bottom: 2rem;
}
.BottomLinks a {
  padding-right: 1rem;
}

@media (max-width: 1092px) {
  .AppBar {
    font-size: 2.4rem;
  }
}
@media (max-width: 1092px) {
  .AppBar {
    font-size: 2.2rem;
  }
}

@media (max-width: 930px) {
  .AppBar {
    font-size: 1.8rem;
  }
  .Logo {
    width: 80px;
    top: 8px;
  }
}

@media (max-width: 768px) {
  .RelatedTopic:nth-child(3n + 2) {
    padding-right: 0;
  }
  .RelatedTopic:nth-child(3n + 3) {
    padding-left: 0;
    padding-right: 0;
  }
  .RelatedTopic:nth-child(3n + 4) {
    padding-left: 0;
  }
  .RelatedTopicContainer {
    text-align: center;

    margin: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media (max-width: 720px) {
  .Logo {
    visibility: hidden;
  }
  .TitlePart {
    margin-top: -25px;
  }
  .TopMenu {
    margin-top: -25px;
    left: 0;
    margin-left: 5px;
    right: 0;
  }
}

@media (max-width: 700px) {
  .AppBar {
    font-size: 1.6rem;
  }
}

@media (max-width: 640px) {
  .AppBar {
    font-size: 1.3rem;
  }

  .CatchPhrase {
    font-size: 0.9rem;
  }
}
