html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}
.italic {
  font-style: italic;
}
.text_center {
  text-align: center !important;
}
.text_right {
  text-align: right !important;
}

.space-right {
  margin-right: 1em !important;
}

.space-left {
  margin-left: 1em !important;
}
.space-bottom {
  margin-bottom: 1em !important;
}

.form-box {
  border-radius: 6px;
  display: block;
  padding: 1.25rem;
  box-shadow: none;
  border: #f0f0f0 1px solid;
}

.mr-5 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 2px;
}
